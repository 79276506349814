<template>
  <div class="container">

    <div class="row justify-content-center align-items-center mt-5 mb-5" style="min-height: calc(100vh - 6rem)">
      <div class="col-12 col-sm" style="max-width: 600px">

        <h5 class="mb-3"> กรุณากรอกข้อมูลของคุณ</h5>

        <div class="card mb-5">
          <div class="card-body">

            <div class="form-group">
              <div>
                ชื่อ-นามสกุล
              </div>

              <input class="form-control" placeholder="ชื่อ-นามสกุล" v-model="form.name"/>
              <error-message class="mt-1" :msg="errorMessage.name"></error-message>
            </div>

            <div class="form-group">
              <div>
                อีเมล
              </div>

              <input class="form-control" placeholder="อีเมล" v-model="form.email"/>
              <error-message class="mt-1" :msg="errorMessage.email"></error-message>
            </div>


            <div class="form-group">
              <div>
                เบอร์โทรศัพท์มือถือ
              </div>

              <input class="form-control" placeholder="เบอร์โทรศัพท์มือถือ" v-model="form.phone"
                     inputmode="numeric" maxlength="10"/>
              <error-message class="mt-1" :msg="errorMessage.phone"></error-message>
            </div>


            <div class="form-group" v-if="item.type !== 'Redeem Code'">
              <div>
                ที่อยู่จัดส่ง
              </div>

              <textarea class="form-control" placeholder="ที่อยู่จัดส่ง"
                        v-model="form.address" rows="3"> </textarea>
              <error-message class="mt-1" :msg="errorMessage.address"></error-message>
            </div>


          </div>
        </div>


        <div class="w-100 btn btn-primary" @click="save()">
          บันทึก
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import validate from 'validate.js';
import ErrorMessage from "../components/ErrorMessage";
import store from '../store';
import {
  GENERATE_TRANSACTION,
  GET_ITEM_INFO, SET_USER_INFO
} from "../store/actions.type";
import {mapState} from 'vuex';


export default {
  name: 'Home',
  components: {ErrorMessage},
  computed: {
    ...mapState({
      payment: state => state.payment,
      item: state => state.payment.itemInfo,
      rpId: state => state.payment.rpId,
      user: (state) => state.payment.userInfo,
    }),
  },
  async created() {
    if (this.item.unique_id) {
      this.form.type = this.item.type;

      if (this.user) {
        let {name, address, email, phone} = this.user;
        this.form.name = name || null;
        this.form.phone = phone || null;
        this.form.email = email || null;
        this.form.address = address || null;
      }
    } else {
      await this.$router.push('/message')
    }
  },
  data() {
    return {
      form: {
        "name": "",
        "phone": "",
        "address": "",
        "email": ""
      },
      errorMessage: {}
    }
  },
  methods: {
    async save() {
      let errorMessage = this.validateForm(this.form);

      if (errorMessage) {
        return this.errorMessage = errorMessage;
      } else {
        this.errorMessage = {};
      }

      if (this.isLoading) return;
      this.isLoading = true;

      let user = {
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        address: this.form.address
      };
      this.$cookies.set('user', user);
      await store.dispatch(SET_USER_INFO, this.form);
      await this.$router.push('/product-detail');

      this.isLoading = false;

    },

    validateForm(data) {
      let validateRule = null;
      let errMessageList = null;

      validateRule = {
        name: {
          presence: {
            allowEmpty: false,
            message: "^โปรดระบุ ชื่อ-นามสกุล"
          },
        },
        phone: {
          presence: {
            allowEmpty: false,
            message: "^โปรดระบุ เบอร์โทรศัพท์มือถือ"
          },
          numericality: {
            message: "^เบอร์โทรศัพท์มือถือ ไม่ถูกต้อง"
          },
          length: {
            is: 10,
            message: "^เบอร์โทรศัพท์มือถือ ไม่ถูกต้อง"
          },
          format: {
            pattern: "^0[0-9]{9}",
            message: "^เบอร์โทรศัพท์มือถือ ไม่ถูกต้อง"
          }
        },
        email: {
          presence: {
            allowEmpty: false,
            message: "^โปรดระบุ อีเมล"
          },
          email: {
            message: "^อีเมล ไม่ถูกต้อง"
          },
        },
      };

      if (this.item.type !== 'Redeem Code') {
        validateRule.address = {
          presence: {
            allowEmpty: false,
            message: "^โปรดระบุ ที่อยู่"
          },
        };
      }

      errMessageList = validate(
          {
            name: data.name,
            phone: data.phone,
            email: data.email,
            address: data.address
          }, validateRule);

      if (errMessageList) {
        const error = {};
        for (const [key, value] of Object.entries(errMessageList)) {
          error[key] = value[0];
        }
        return error;
      } else {
        return false;
      }

    },


  }
}
</script>
